import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { GetServerSideProps, Redirect } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import {
  createClient,
  fetchApiHubServerSide,
  getProjectInfo,
  LocaleStorage,
  ResponseError,
  setProjectInfo,
  useAccount,
  useCart,
  useDarkMode,
} from '@frontastic-engbers/lib';
import { getTrackingConfig } from '@frontastic-engbers/lib/actions/config';
import { FrontasticRenderer } from '@frontastic-engbers/lib/lib/renderer';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
import { getPageType } from '@frontastic-engbers/helpers/dataLayerHelper/pageTypeHelper';
import { Log } from '@frontastic-engbers/helpers/errorLogger';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { getMetaRobotsContent, useCanonical } from '@frontastic-engbers/helpers/seoHelper';
import {
  createRedirect,
  getSSRRedirect,
  getUrlFromParams,
  routeChangeRedirect,
} from '@frontastic-engbers/helpers/utils/authRedirect';
import { MetaTags } from '@frontastic-engbers/helpers/metaTags';
import { StructuredData } from '@frontastic-engbers/helpers/structuredData';
import { GetAccountResultPicked } from '@frontastic-engbers/types/account/Account';
import { tastics } from '@engbers/tastics';
import { RecoilRoot } from 'recoil';
import GTM from 'react-gtm-module';
import styles from './slug.module.css';
import { FullPageLoadingIndicator, ModalWrap, NotificationWrap } from '@engbers/components';
import { Usercentrics } from '@engbers/components/usercentrics';
import { CleverPush } from '@engbers/components/cleverpush';
import { BackToTopBtn } from '@engbers/components/back-to-top-button';
import { Econda } from '@engbers/components/econda';
import { siteUrl } from 'next-sitemap.config';
import { BreadcrumbHelper } from '@frontastic-engbers/helpers/breadcrumbHelper';

const HARDCODED_REDIRECTS = {
  '/trends/modehighlights': '/new-style',
  '/new-style/get-inspiration': '/outfits',
  '/new-style/get-inspiration/blättere-im-lookbook':
    'https://api.meleven.de/out/engbers/07.d9.59.MehrwertThemenFS24web905fd850.pdf',
};

type SlugProps = {
  // This needs an overhaul. Can be too many things in my opinion (*Marcel)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // data: RedirectResponse | PageDataResponse | ResponseError | { ok: string; message: string } | string;
  locale: string;
  accept: string;
};

export default function Slug({ data, locale, accept }: SlugProps) {
  LocaleStorage.locale = locale;
  LocaleStorage.accept = accept;

  setProjectInfo({
    name: 'emilio_EA',
    projectId: 'ea',
    siteUrl,
  });

  const projectInfo = getProjectInfo();
  const { applyTheme } = useDarkMode();
  const router = useRouter();
  const { loggedIn, loaded, account } = useAccount();
  const { data: cart } = useCart();
  const cartChecked = useRef<boolean>(false);
  const { formatMessage } = useFormat({ name: 'common' });
  const tagmanager = new TagManager();
  const { getCanonical } = useCanonical();

  const metaRobots = useMemo(() => {
    return getMetaRobotsContent(router.query, router.asPath, data?.pageFolder);
  }, [router.asPath]);

  const handleRouteChange = useCallback(
    (url) => {
      routeChangeRedirect({
        loaded,
        loggedIn,
        url,
        account,
        router,
      });
    },
    [loaded, loggedIn],
  );

  useEffect(() => {
    const getConfig = async () => {
      const config = await getTrackingConfig();

      GTM.initialize({
        gtmId: config.gtmContainerId,
        auth: config.gtmEnvironmentAuth,
        preview: `env-${config.gtmEnvironmentId}`,
      });
    };

    if (data.awinCookie) {
      localStorage.setItem('awinCookie', JSON.stringify(data.awinCookie));
    }

    getConfig();
  }, []);

  useEffect(() => {
    if (getPageType(data) === 'other' || getPageType(data) === 'home' || getPageType(data) === 'nosearchresults') {
      tagmanager
        .customEvent('ecomm', {
          ecomm_pagetype: getPageType(data),
          ecomm_prodid: [],
          ecomm_totalvalue: 0,
        })
        .executePush();
    }
  }, [data]);

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);
    return () => router.events.off('routeChangeStart', handleRouteChange);
  }, [loaded, loggedIn]);

  useEffect(() => {
    if (cartChecked.current || !cart) {
      return;
    }

    cartChecked.current = true;
    const path = router.asPath;

    if (path.includes('/checkout') && !cart.lineItems?.length) {
      router.push('/');
    }
  }, [router.query.path, router, cart]);

  useEffect(() => {
    applyTheme(data?.pageFolder?.configuration?.theme);
  }, [data?.pageFolder?.configuration]);

  if (!data || typeof data === 'string') {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    );
  }

  if (!data!.ok && data!.message) {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data!.message}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    );
  }

  return (
    <>
      <Head>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
                function gtag() {
                dataLayer.push(arguments)
              }

                gtag("consent", "default", {
                ad_user_data: "denied",
                ad_personalization: "denied",
                ad_storage: "denied",
                analytics_storage: "denied",
                wait_for_update: 2000 // milliseconds to wait for update
              });

              gtag("set", "ads_data_redaction", true);
            `,
          }}
        />
        <meta
          name="description"
          content={formatMessage({
            id: 'meta.desc',
            defaultMessage: 'Find largest shopping collections here!',
          })}
        />
        <meta name="robots" content={metaRobots} />
        <link {...getCanonical()} />
      </Head>
      <MetaTags {...data.pageFolder.configuration} siteUrl={siteUrl} />
      <StructuredData projectId={projectInfo?.projectId} structuredData={data.structuredData} siteUrl={siteUrl} />
      <RecoilRoot>
        <FullPageLoadingIndicator />
        <Usercentrics />
        <CleverPush />
        <Econda data={data} account={loggedIn ? account : undefined} locale={locale} />
        <FrontasticRenderer data={data} tastics={tastics} wrapperClassName={styles.gridWrapper} />
        <ModalWrap />
        <NotificationWrap />
        <BackToTopBtn />
      </RecoilRoot>
    </>
  );
}

export const getServerSideProps: GetServerSideProps | Redirect = async ({ params, locale, query, req, res }) => {
  LocaleStorage.locale = locale;

  const url = getUrlFromParams(params);
  const hardcodedRedirect = HARDCODED_REDIRECTS[url];

  if (hardcodedRedirect) {
    return createRedirect(hardcodedRedirect);
  }

  const { loggedIn, account } = (await fetchApiHubServerSide('/action/account/getAccount', {
    req,
    res,
  })) as GetAccountResultPicked;

  const ssrRedirect = getSSRRedirect({
    loggedIn,
    account,
    params,
  });

  if (ssrRedirect) {
    return ssrRedirect;
  }

  if (url.endsWith('.html')) {
    return createRedirect(url.replace('.html', ''));
  }

  const frontastic = createClient();
  const data: any = await frontastic.getRouteData(params, locale, query, req, res);
  const structuredData: any = await fetchApiHubServerSide('/action/config/getStructuredData', {
    req,
    res,
  });

  const NOT_FOUND_PAGE_SLUG = 'notfound';
  const notFoundPage = await frontastic.getRouteData(
    { slug: [NOT_FOUND_PAGE_SLUG] },
    locale,
    {
      ...query,
      path: `/${NOT_FOUND_PAGE_SLUG}`,
    },
    req,
    res,
  );

  if (params.slug?.length === 1 && params.slug?.[0] === NOT_FOUND_PAGE_SLUG) {
    // set 404 for the notfound content page as well
    res.statusCode = 404;
  }

  if (data) {
    if (data instanceof ResponseError && data.getStatus() == 404) {
      res.statusCode = 404;

      return {
        props: {
          data: notFoundPage || null,
          locale: locale,
          ...(await serverSideTranslations(locale, [
            'common',
            'cart',
            'product',
            'checkout',
            'account',
            'error',
            'success',
            'wishlist',
            'newsletter',
          ])),
        },
      };
    } else if (typeof data === 'object' && 'target' in data) {
      return {
        redirect: {
          destination: data.target,
          statusCode: data.statusCode,
        } as Redirect,
      };
    }
  }

  if (data instanceof Error) {
    // @TODO: Render nicer error page in debug mode, which shows the error to
    // the developer and also outlines how to debug this (take a look at
    // frontastic-CLI).
    Log.error('Error retrieving data: ', data);

    res.statusCode = 404; // TODO: should be changed to 500 once a proper error page is implemented

    return {
      props: {
        data: notFoundPage || null,
        locale: locale,
        ...(await serverSideTranslations(locale, [
          'common',
          'cart',
          'product',
          'checkout',
          'account',
          'error',
          'success',
          'wishlist',
          'newsletter',
        ])),
      },
    };
  }

  if (typeof data === 'string') {
    return {
      props: {
        data: { error: data },
        error: data,
      },
    };
  }

  if (
    (data as any)!.message === 'Could not resolve page from path' ||
    (data as any)?.message?.startsWith?.('No active page for node')
  ) {
    res.statusCode = 404;

    return {
      props: {
        data: notFoundPage || null,
        locale: locale,
        ...(await serverSideTranslations(locale, [
          'common',
          'cart',
          'product',
          'checkout',
          'account',
          'error',
          'success',
          'wishlist',
          'newsletter',
        ])),
      },
    };
  }

  data.pageFolder.customBreadcrumb = await new BreadcrumbHelper(
    process.env.NEXT_PUBLIC_FRONTASTIC_HOST,
    'de_DE',
  ).getBreadcrumb(data.pageFolder, url);

  data.awinCookie = req.cookies.aw ?? null;
  data.structuredData = structuredData;

  return {
    props: {
      data: data || null,
      locale: locale,
      accept: req.headers.accept,
      ...(await serverSideTranslations(locale, [
        'common',
        'cart',
        'product',
        'checkout',
        'account',
        'error',
        'success',
        'wishlist',
        'newsletter',
      ])),
    },
  };
};
