import { Redirect } from 'next';
import { NextRouter } from 'next/router';
import { Account } from '@frontastic-engbers/types/account/Account';
import { isAccountAuthPath, isPwaUser, isPwaUserPath } from './accountAuth';

type Params = {
  slug?: string[];
};

type SSRRedirectParams = {
  loggedIn: boolean;
  account: Account;
  params: Params;
};

type RedirectParams = Omit<SSRRedirectParams, 'params'> & {
  url: string;
  loaded: boolean;
  router: NextRouter;
};

type RedirectResult = {
  redirect: Redirect;
};

const cancelRouting = (router: NextRouter, url: string) => {
  // See https://github.com/zeit/next.js/issues/2476
  router.events.emit('routeChangeError');
  router.push(url);
  throw `routeChange aborted. This error can be safely ignored - https://github.com/zeit/next.js/issues/2476.`;
};

export const routeChangeRedirect = ({ loaded, loggedIn, url, account, router }: RedirectParams) => {
  if (loaded) {
    if (!loggedIn && isAccountAuthPath(url)) {
      cancelRouting(router, '/login');
    }

    if (loggedIn && isPwaUserPath(url) && !isPwaUser(account)) {
      cancelRouting(router, '/mein-konto');
    }
  }
};

export const createRedirect = (destination: string) => ({
  redirect: {
    destination,
  } as Redirect,
});

export const getUrlFromParams = (params: Params) => {
  const slug = params.slug?.join('/') || '';

  return `/${slug !== 'index' ? slug : ''}`;
};

export const getSSRRedirect = ({ loggedIn, account, params }: SSRRedirectParams): RedirectResult | null => {
  const url = getUrlFromParams(params);

  if (!loggedIn && isAccountAuthPath(url)) {
    return createRedirect('/login');
  }

  if (loggedIn && isPwaUserPath(url) && !isPwaUser(account)) {
    return createRedirect('/mein-konto');
  }

  return null;
};
