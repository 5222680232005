import Script from 'next/script';
import { useEffect, useState } from 'react';
import { getEcondaConfig } from '@frontastic-engbers/lib/actions/config';
import { EcondaConfigResponse } from '@frontastic-engbers/types/engbers-custom';
import { PageDataResponse } from '@frontastic-engbers/lib/lib/types';
import { Account } from '@frontastic-engbers/types/account/Account';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
import { getEcondaData } from '@frontastic-engbers/helpers/dataLayerHelper/econdaDataHelper';

interface EcondaProps {
  data?: PageDataResponse;
  account?: Account;
  locale?: string;
}

export const Econda: React.FC<EcondaProps> = ({ data, account, locale = 'de' }) => {
  const [econdaConfig, setEcondaConfig] = useState<EcondaConfigResponse>(null);

  useEffect(() => {
    if (
      data &&
      data.pageFolder.pageFolderType !== 'frontastic/checkout' &&
      data.pageFolder.pageFolderType !== 'frontastic/thank-you' &&
      data.pageFolder.pageFolderType !== 'frontastic/search' &&
      data.pageFolder.pageFolderType !== 'frontastic/search/zero-results'
    ) {
      new TagManager().econdaEvent(data, account, locale).executePush();
      sessionStorage.setItem('econda_data_layer', JSON.stringify(getEcondaData(data, account, locale)));
    }
  }, [data]);

  useEffect(() => {
    const getConfig = async () => {
      const config = await getEcondaConfig();
      setEcondaConfig(config);

      if (config?.siteId) {
        window.emos3 = window.emos3 || {
          defaults: {
            siteid: config.siteId,
            countryid: 'DE',
            langid: 'de',
          },
          stored: [],
          send: function (p: any) {
            this.stored.push(p);
          },
        };
      }
    };

    window.econda = {
      onReady: [],
      ready: function (f: any) {
        this.onReady.push(f);
      },
    };

    getConfig();
  }, []);

  if (!econdaConfig?.clientKey || !econdaConfig.containerId || !econdaConfig.siteId) {
    return null;
  }

  return (
    <Script
      src={'https://l.ecn-ldr.de/loader/loader.js'}
      client-key={`${econdaConfig.clientKey}`}
      container-id={`${econdaConfig.containerId}`}
      defer={true}
    />
  );
};
